.share_modal{
    padding: 3rem 2.5rem !important;
}

.modal_title{
    font-size: 1.8rem;
    margin: $margin-large 0px;
    color: $primary-color;
}

p{
    color: $grey;
}

.share_details{
    width: 100%;
    border-radius: $border-radius-medium;
    margin: $margin-large 0px;
    border: 1px solid $border-color;
    overflow: hidden;
    
    th, td{
        padding: 0.8rem 1rem;
        font-size: 0.8rem;
    }

    td{
        color: $grey;
        font-style: italic;
    }

    th {
        text-align: left;
        color: $charcoal;
        font-weight: 600;
    }

    thead th{
        text-align: center;
        border-bottom: 1px solid lighten($border-color, 40%);
        font-weight: bold;
        color: $primary-color;
        padding: 0.5rem 1rem;
    }
}