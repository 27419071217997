.load_more{
    position: relative;
    display: flex;
    justify-content: center;
    padding: $padding-medium;
}

.load_more__button{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    background-color: white;
    border-radius: 50%;
    border: 1px solid $border-color;
    cursor: pointer;
    transition: all 0.1s ease-in-out;

    &:hover{
        background-color: darken(white, 2%);
    }
}