button-base{
    @extend button;
    outline: none;
    border: none;
    cursor: pointer;
    text-decoration: none;
    &:focus{
        outline: none;
    }

    &:disabled{
        cursor: default;
        opacity: 0.5;
        pointer-events: none;
    }
    
    &.button_fit{
        width: fit-content;
    }
}

button{
    font-family: inherit;
}

.button{
    @extend button-base;
    position: relative;
    width: 100%;
    background-color: $primary-color;
    padding: 0.65rem 0.8rem;
    color: white !important;
    border-radius: $border-radius-small;
    transition: background 0.3s ease-in-out;
    
    &:hover{
        background-color: darken($primary-color, 10%);
    }   
    
    &.cancel{
        background-color: $charcoal;
    }
}

.add_to_ad{
    align-self: center;
    display: flex;
    font-size: 0.8rem;
    line-height: 1rem;
    width: fit-content;
    align-items: center;
    padding: 0px;
    background-color: #0078d7;
    overflow: hidden;
    padding: 0.5rem 1rem;
    color: white;
    padding-right: 1rem;
    border: none;
    border-radius: $border-radius-medium;
    flex-shrink: 0;
    font-family: inherit;
    cursor: pointer;
    img{
        width: 35px;
        height: 35px;
        margin-right: 0.5rem;
    }
}

.add_to_ad_content{
    text-align: left;
    font-weight: 300;

    .add_to_ad_content__value{
        font-weight: bold
    }
}

.btn{
    @extend button-base;
    border: 1px solid $border-color;
    padding: 0.6rem 1.2rem;
    font-size: 0.8rem;
    border-radius: 3rem;
    background-color: $primary-color;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    transition: all 0.1s ease;

    &:hover{
        background-color: darken($primary-color, 5%);
    }

    svg{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px 0.3rem
    }

    &.cancel{
        background-color: transparent;
        color: $primary-color;
        border-color: $primary-color;

        &:hover{
            border-color: $primary-color;
            background-color: $primary-color;
            color: white;
        }
    }
}
