.vaults{
    margin-top: $margin-large;
}

.vault_list{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 20px;
    margin-top: $margin-large;
}

.vault_list_item{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    height: 300px;
    flex-direction: column;
    padding: $padding-large $padding-medium;
    border-radius: $border-radius-medium;
    box-shadow: 0px 0px 15px rgba(black, 0.05);
    cursor: pointer;
    transition: all 0.3s ease;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    overflow: hidden;

    .vault_list_item__lock{
        position: absolute;
        display: flex;
        padding-right: 10px;
        padding-top: 10px;
        font-size: 1.2rem;
        color: white;
        justify-content: flex-end;
        top: 0;
        right: 0;
        width: 45px;
        height: 45px;
        background-color: $primary-color;
        border-bottom-left-radius: 30px;
    }

    &:hover{
        transform: translate(0, -2px);
        background-color: darken(white, 2%);
    }
}

.vault_list_item__icon{
    position: relative;
    width: 80px;
    height: 80px;
    background: radial-gradient(rgba($primary-color, 80%) 0%, rgba(lighten($primary-color, 10%), 80%));
    color: white;
    margin-bottom: $margin-large;
    border-radius: 50%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    box-shadow: 0px 0px 8px rgba(black, 30%);
    overflow: hidden;

    span{
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: 50%;
    }
}

.vault_list_item__title{
    font-weight: 600;
    color: $charcoal;
    font-size: 1.2rem;
    margin-bottom: 0.3rem;
}

.vault_list_item__subtitle{
    font-weight: 300;
    color: #888;
}