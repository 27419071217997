.opt_form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .btn{
        width: 100%;
        svg{
            font-size: 1.5rem;
        }
    }
}

.opt_input{
    margin-bottom: $margin-large;
    margin-top: $margin-medium;
    width: fit-content !important;

    &.error{
        animation: otpErrorShake 0.2s;
        input {
            border-color: $warning-red !important;
        }
    }

    input{
        margin-right: $margin-medium;
        border: 1px solid $border-color !important;
        border-radius: 50% !important;
        width: 45px !important;
        height: 45px !important;
        font-family: inherit !important;
        font-size: 1rem !important;

        &:focus{
            border-color: $primary-color !important;
        }
    }
}

@keyframes otpErrorShake {
    0%{
        transform: translateX(-5px);
    }
    
    33%{
        transform: translateX(5px);
    }
    
    66%{
        transform: translateX(-5px);
    }

    100%{
        transform: translateX(0px);
    }
}

.otp_error{
    color: $warning-red;
    font-size: 0.8rem;
    font-weight: 700;
    margin-top: $margin-medium;
}