.login_page {
    position: fixed;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background: rgba($primary-color, 0.1);
}

.login_page__container {
    background: white;
    width: 95%;
    max-width: 1000px;
    height: 85%;
    display: flex;
    position: relative;
    max-height: 750px;
    border-radius: $border-radius-medium;
    overflow: hidden;
    box-shadow: 0 0 15px rgba(0,0,0,.1);
    
    .container{
        align-items: center;
        justify-content: center;
    }

    &::before{
        position: absolute;
        width: 50%;
        top: 0;
        height: 100%;
        content: "";
        background: rgba($primary-color, 0.25);
        left: 0;
        @media(max-width: $mobile-breakpoint){
            display: none;
        }
    }

    @media screen and (max-width: $mobile-breakpoint) {
        width: 100%;
        height: 100%;
    }
}

img.login_page__image {
    display: block;
    max-height: 400px;
    max-width: 400px;
    margin: auto;
    padding: $padding-large;
    @media(max-width: $mobile-breakpoint){
        display: none;
    }
}

img.login_page__logo{
    display: block;
    max-height: 50px;
    margin: auto;
    margin-bottom: $margin-large;
}

.login_code_container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: $padding-large
}

.login_code{
    margin-top: $margin-large;
    max-width: 200px;
    max-height: 200px;
}

.expiry_countdown{
    color: $grey;
    font-size: 0.8rem;
    font-weight: 600;
    margin-bottom: $margin-large;
}

.login_trigger{
    font-size: 0.8rem;
    color: $grey;
    cursor: pointer;
    margin-top: $margin-large;
    text-decoration: underline;
}

.qr_form_loader{
    position: relative;
}

.regen_code_button{
    color: $primary-color;
    text-decoration: underline;
    margin-bottom: $margin-medium;
    font-size: 0.8rem;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover{
        color: darken($primary-color, 10%);
    }
}

.logout_microsoft{
    margin-bottom: 0px;
}

.qr_form{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: $padding-medium;
}

.qr_form_title{
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: $margin-medium;
}

.qr_form_body{
    color: $grey;
}

.store_badges{
    display: flex;
    flex-basis: 0;

    img{
        height: 40px;
        flex-grow: 1;
        margin-right: $margin-small;
        margin-top: $margin-medium;

        &:last-child{
            margin-right: 0px;
        }
    }
}