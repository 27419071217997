.alert_box{
    background-color: lighten($primary-color, 50%);
    padding: 0.8rem $padding-medium;
    font-size: 0.8rem;
    border-radius: $border-radius-small;
    color: darken($primary-color, 10%);
}

.alert_box.alert-danger{
    @extend .alert_box;
    background-color: lighten($warning-red, 25%);
    color: $grey;
}

.alert_box.alert-success{
    @extend .alert_box;
    background-color: lighten($online-color, 25%);
    color: darken($online-color, 25%);;
}

.alert_box.alert-clear{
    @extend .alert_box;
    background-color: transparent;
    color: $grey;
}

.share_badge{
    padding: $padding-medium;
    background-color: lighten($primary-color, 50%);
    border-radius: $border-radius-large;
    border: 1px solid rgba($primary-color, 0.1);

    b{
        font-weight: 600;
    }

    p{
        margin-bottom: 0px;
    }
}

.share_bade__heading{
    font-size: 0.8rem;
    color: $grey;
    font-weight: 600;
    margin-bottom: 1rem;
    text-align: right;
    border-bottom: 1px solid $border-color;
    padding-bottom: 0.6rem;
}

.share_bade__subheading{
    font-weight: 300;
    color: $charcoal;
}

.share_bade__company{
    font-size: 0.8rem;
    font-weight: 600;
    color: $primary-color;
}