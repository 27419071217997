.landing_page{
    display: flex;
    justify-content: center;
    overflow-y: auto;
    padding-bottom: 100px !important;

    .form_select {
      margin-right: $margin-medium;
      width: 200px;
    }
}

.landing_page__container{
    position: relative;
    width: 100%;
    max-width: $contained-width;
}

.landing_page_header{
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: $margin-large;
  padding-top: $padding-large;
  margin-bottom: $margin-large;
  padding-bottom: $padding-large;
  align-items: center;
  .link{
    display: flex;
    align-items: center;
    margin-bottom: 0.2rem;
    svg{
      margin-right: 0.3rem;
    }
  }
  h1{
    margin-bottom: 0px;
  }


  h2{
    margin-top: 0px;
  }


  .landing_page_header_logo{
    font-size: 1.2rem;
    text-align: right;
    font-weight: 300;
    font-style: italic;
    color: lighten(grey, 5%);

    img{
      margin-bottom: -15px;
    }
  }


  .landing_page_details{
    flex-grow: 1;
  }

  img{display: block;
    flex-shrink: 0;
    max-width: 250px;
    width: 100%;
    position: relative;
    height: fit-content;
  }
}

.subtitle{
    font-weight: 600;
}

.title-thin{
    font-weight: 300;
}

.document_list{
    padding-bottom: $padding-large;

    h2{
      font-weight: 500;
      color: $grey;
      font-size: 1.3rem;
    }

    table {
      table-layout: fixed;
      width: 100%;
      margin-bottom: $margin-large;
      tbody tr {
        display: table-row;
        cursor: pointer;
        
        &:hover{
          td{
            background-color: darken(white, 3%);
          }
        }

        td {
          background-color: white;
          width: 1%; // set minimum size to allow flex grow
          
          &:first-child {
            width: 0; // reset first column
          }
          
          &:not(:last-child) {
            padding-right: 20px; // add spacing between columns
          }
        }
        
        td:not([colspan=""]) { // exclude merged cells
          flex-grow: 1;
        }

        &:last-child{
          td:first-child{
            border-bottom-left-radius: $border-radius-large;
          }

          td:last-child{
            border-bottom-right-radius: $border-radius-large;
          }
        }
      }

      th:first-child{
        border-top-left-radius: $border-radius-large;
      }

      th:last-child{
        border-top-right-radius: $border-radius-large;
      }
    }
    
}

.document_list_filters{
  display: flex;
  justify-content: space-between;

  input{
    width: 250px;
  }
}