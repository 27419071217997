.transcripts_main_page{
    @extend .degree_content_main;
    overflow-y: auto;
    display: block;
}

.transcript_page__heading{
    font-size: 1rem;
    color: $primary-color;
    font-weight: 300;
    margin-bottom: $margin-medium;
}

.transcript_page__subheading{
    font-size: 1.8rem;
    color: $charcoal;
    margin-bottom: $margin-small;
}

.transcript_header{
    border-bottom: 1px solid $border-color;
    margin-bottom: $margin-large;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.transcript_header__logo{

    img{
        max-height: 50px;
        max-width: 220px;
    }
}

.transcript_container{
    background-color: white;
    border-radius: $border-radius-large;
    max-width: 1000px;
    box-shadow: 7.5px 7.5px 35px rgba(black, 0.1);
    overflow: hidden;
    margin: auto;

    .not_found{
        text-align: center;
        padding-top: $padding-medium;
    }
}

.transcript_container__content{
    padding: 2rem 3em;
}

.transcript_year{
    width: 100%;
    margin-bottom: $margin-large;

    &:last-child{
        margin-bottom: 0px;
    }
}

.transcript_year_title{
    text-align: center;
    color: $primary-color;
    font-weight: 600;
    font-size: 1.2rem;
    margin-bottom: 0.1rem;
}

.transcript_year_subtitle{
    text-align: center;
    font-weight: 600;
    font-size: 0.8rem;
    font-style: italic;
    color: $grey;
    margin-bottom: $margin-large;
}

.transcript_table{
    width: 100%;
    table-layout: fixed;

    col.transcript_description{
        width: 40%;
    }

    th{
        text-align: left;
        font-weight: 600;
        padding-bottom: $padding-small;
        
        &.center{
            text-align: center;
        }
    }

    td{
        font-weight: 300;
        padding: 0.2rem 0px;

        &.center{
            text-align: center;
        }
    }
}

.transcript_footer{
    display: flex;
    align-items: center;
    color: white;
    justify-content: space-between;
    background-color: $primary-color;
    padding: 0.65rem 1.5rem;
    font-size: 0.8rem;

    a{
        color: white;
    }
}

.transcript_footer__info__title{
    font-weight: 600;
}

.transcript_footer__info__subtitle{
    font-weight: 300;
    color: $light-grey;
}