.registration_page{
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    top: 0;
    height: 100%;
    left: 0;
    background: rgba($primary-color, 0.1);
}

.registration_page__container{
    width: 90%;
    max-width: 500px;
    height: 90%;
    max-height: 700px;
    background-color: white;
    padding: $padding-large;
    border-radius: $border-radius-medium;
    box-shadow: 2.5px 2.5px 25px rgba(black, 0.1);
    border: 1px solid $border-color;
    display: flex;
    overflow: hidden;
    flex-direction: column;

    &.pin{
        max-height: 500px;
    }
}

.registration_page__step__success{
    display: flex;
    align-items: center;
    flex-direction: column;
}

.registration_page__header__logo{
    align-self: flex-end;
    margin-bottom: $margin-large;
}

.registration_page__step_container{
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
}

.registration_page__header__title{
    font-size: 1.5rem;
    font-weight: 600;
    color: $primary-color;
}

.registration_page__header{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;

    img{
        max-height: 40px;
    }

    p{
        font-size: 0.8rem;
        color: $grey;
    }
}

.registration_page__step{
    flex: 1 1 0px;
    padding: $padding-large;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    opacity: 0.5;
    background-color: $border-color;
    display: none;
    animation: slideInLeft 0.8s ease;

    &:first-child{
        animation: fadeIn 0.8s ease;
    }

    &.active{
        opacity: 1;
        background-color: white;
        display: flex;
    }

    &.complete{
        display: flex;
        opacity: 1;
        background-color: white;
        border-color: $online-color;

        .registration_page__step__number{
            background-color: $online-color;
        }
    }

    .qr_form{
        padding: 0px;
        flex-grow: 1;
    }

    .login_code{
        border: 3px solid rgba($primary-color, 0.8);
        border-radius: $border-radius-large;
        box-shadow: 2.5px 2.5px 25px rgba(black, 0.3);       
        animation: fadeIn 0.6s ease; 
    }
}

@keyframes slideInLeft{
    from{
        opacity: 0;
        transform: translateX(15%);
    }

    to{
        opacity: 1;
        transform: translateX(0%);
    }
}

.registration_process{
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: $margin-medium;

    &.completed {
        .registration_process_step__line{
            background-color: $online-color !important; 
        }
    }
}

.registration_process_step{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $primary-color;
    font-weight: 600;
    width: 45px;
    height: 45px;
    background-color: white;
    border: 1px solid $light-grey;
    border-radius: 50%;
    z-index: 1;
    overflow: hidden;
    transition: all 0.3s ease;

    .registration_process_step__label{
        display: flex;
        align-items: center;
        font-size: 0.9rem;
        line-height: 0.9rem;
        margin-top: -1px;
    }

    svg{
        font-size: 1.2rem;
    }

    &.active{
        color: white;
        font-weight: bold;
        border-color: $primary-color;

        &::before{
            position: absolute;
            width: calc(100% - 10px);
            height: calc(100% - 10px);
            background-color: $primary-color;
            content: "";
            left: 5px;
            top: 5px;
            border-radius: 50%;
            z-index: -1;
        }
    }

    &.complete{
        color: white;
        border-color: $online-color;
        
        & ~ .registration_process_step__line{
            background-color: $light-grey;
        }

        & + .registration_process_step__line{
            background-color: $primary-color;
        }

        &::before{
            position: absolute;
            width: calc(100% - 10px);
            height: calc(100% - 10px);
            background-color: $online-color;
            content: "";
            left: 5px;
            top: 5px;
            border-radius: 50%;
            z-index: -1;
        }
    }
}

.registration_breadcrumbs{
    background-color: white;
    display: flex;
    justify-content: space-between;
    border-radius: $border-radius-large;
    overflow: hidden;
    padding: 0px -2px;
    margin-top: $margin-large;
    flex-shrink: 0;

    &.completed{
        display: none;
    }
}

.registration_breadcrumbs__item{
    position: relative;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba($primary-color, 0.1);
    margin-right: 5px;
    flex-grow: 1;
    flex-basis: 0;
    height: 50px;
    font-size: 0.75rem;
    color: $primary-color;

    .success-checkmark{
        display: none;
    }

    .registration_breadcrumbs__item__label{
        font-weight: bold;
        font-size: 0.8rem;
    }

    &.active{
        background-color: $primary-color;
        color: white;
        &::after {
            border-left-color: $primary-color;
        }
    }

    &.complete{
        .registration_breadcrumbs__item__label, .registration_breadcrumbs__item__description{
            display: none;
        }

        .success-checkmark{
            display: block;
        }
    }

    &:first-child{
        padding-left: 5px;
    }

    &:nth-child(n+2)::before{
        position: absolute;
        top:0;
        left:0;
        display: block;
        border-left: 15px solid white; /* width: arrow width, color: background of document */
        border-top: 25px solid transparent; /* width: half height */
        border-bottom: 25px solid transparent; /* width: half height */
        width: 0;
        height: 0;
        content: " ";
    }

    &::after {
        z-index: 1; /* need to bring this above the next item */
        position: absolute;
        top: 0;
        right: -15px; /* arrow width (negated) */
        display: block;
        border-left: 15px solid rgba($primary-color, 0.1); /* width: arrow width */
        border-top: 25px solid transparent; /* width: half height */
        border-bottom: 25px solid transparent; /* width: half height */
        width:0;
        height:0;
        content: " ";
    }

    &:last-child{
        margin-right: 0px;
        &::after {
            display: none;
        } 
    }
}

.registration_process_step__line{
    height: 2px;
    flex-grow: 1;
    background-color: $light-grey;
    transition: all 0.3s ease;
}

.registration_page__step__number{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 50px;
    height: 50px;
    background-color: $primary-color;
    color: white;
    font-weight: 700;
    border-radius: 50%;
    font-size: 20px;
    margin-bottom: $margin-large;
}

.registration_page__step__title{
    display: block;
    text-align: center;
    font-weight: 500;
}

.registration_page__step p{
    display: block;
    text-align: center;
    color: $grey;
}

.signin_microsoft_button{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $primary-color;
    border: 1px solid $primary-color;
    padding: 0.5rem 0.3rem;
    cursor: pointer;
    font-weight: 500;
    border-radius: $border-radius-small;
    color: white;
    margin-top: $margin-large;
    transition: all 0.2s ease;
    
    &:hover{
        background-color: white;
        color: $primary-color;
    }

    img{
        width: 25px;
        height: 25px;
        flex-shrink: 0;
        margin-right: 0.6rem;
    }
}

.university_badge{
    display: flex;
    align-items: center;
    justify-content: center;

    .university_badge__title{
        font-size: 0.8rem;
        font-weight: 600;
        color: $primary-color;
    }
}

.waiting_for_auth{
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
}

.waiting_for_auth__label{
    display: block;
    text-align: center;
    color: $grey;
    font-size: 0.8rem;
    margin-bottom: $margin-small;
}

.auth_pin{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.auth_pin__label{
    font-weight: 600;
    color: $primary-color;
    font-size: 0.8rem;
}

.auth_pin__pin{
    font-size: 1.5rem;
    font-weight: 700;
    color: $charcoal;
}

.success-checkmark {
    width: 80px;
    height: 115px;
    margin: 0 auto;
    
    .check-icon {
        width: 80px;
        height: 80px;
        position: relative;
        border-radius: 50%;
        box-sizing: content-box;
        border: 4px solid #4CAF50;
        
        &::before {
            top: 3px;
            left: -2px;
            width: 30px;
            transform-origin: 100% 50%;
            border-radius: 100px 0 0 100px;
        }
        
        &::after {
            top: 0;
            left: 30px;
            width: 60px;
            transform-origin: 0 50%;
            border-radius: 0 100px 100px 0;
            animation: rotate-circle 4.25s ease-in;
        }
        
        &::before, &::after {
            content: '';
            height: 100px;
            position: absolute;
            background: #FFFFFF;
            transform: rotate(-45deg);
        }
        
        .icon-line {
            height: 5px;
            background-color: #4CAF50;
            display: block;
            border-radius: 2px;
            position: absolute;
            z-index: 10;
            
            &.line-tip {
                top: 46px;
                left: 14px;
                width: 25px;
                transform: rotate(45deg);
                animation: icon-line-tip 0.75s;
            }
            
            &.line-long {
                top: 38px;
                right: 8px;
                width: 47px;
                transform: rotate(-45deg);
                animation: icon-line-long 0.75s;
            }
        }
        
        .icon-circle {
            top: -4px;
            left: -4px;
            z-index: 10;
            width: 80px;
            height: 80px;
            border-radius: 50%;
            position: absolute;
            box-sizing: content-box;
            border: 4px solid rgba(76, 175, 80, .5);
        }
        
        .icon-fix {
            top: 8px;
            width: 5px;
            left: 26px;
            z-index: 1;
            height: 85px;
            position: absolute;
            transform: rotate(-45deg);
            background-color: #FFFFFF;
        }
    }
}

@keyframes rotate-circle {
    0% {
        transform: rotate(-45deg);
    }
    5% {
        transform: rotate(-45deg);
    }
    12% {
        transform: rotate(-405deg);
    }
    100% {
        transform: rotate(-405deg);
    }
}

@keyframes icon-line-tip {
    0% {
        width: 0;
        left: 1px;
        top: 19px;
    }
    54% {
        width: 0;
        left: 1px;
        top: 19px;
    }
    70% {
        width: 50px;
        left: -8px;
        top: 37px;
    }
    84% {
        width: 17px;
        left: 21px;
        top: 48px;
    }
    100% {
        width: 25px;
        left: 14px;
        top: 45px;
    }
}

@keyframes icon-line-long {
    0% {
        width: 0;
        right: 46px;
        top: 54px;
    }
    65% {
        width: 0;
        right: 46px;
        top: 54px;
    }
    84% {
        width: 55px;
        right: 0px;
        top: 35px;
    }
    100% {
        width: 47px;
        right: 8px;
        top: 38px;
    }
}

.success-checkmark.small {
    width: 36px;
    height: 36px;
    margin: 0 auto;
    
    .check-icon {
        width: 32px;
        height: 32px;
        position: relative;
        border-radius: 50%;
        box-sizing: content-box;
        border: 2px solid $primary-color;
        
        &::before {
            top: 1.2px;
            left: -0.8px;
            width: 12px;
            transform-origin: 100% 50%;
            border-radius: 40px 0 0 40px;
        }
        
        &::after {
            top: 0;
            left: 12px;
            width: 24px;
            transform-origin: 0 50%;
            border-radius: 0 40px 40px 0;
            animation: rotate-circle-small 4.25s ease-in;
        }
        
        &::before, &::after {
            content: '';
            height: 40px;
            position: absolute;
            background: transparent;
            transform: rotate(-45deg);
        }
        
        .icon-line {
            height: 2px;
            background-color: $primary-color;
            display: block;
            border-radius: 0.8px;
            position: absolute;
            z-index: 10;
            
            &.line-tip {
                top: 18.4px;
                left: 5.1px;
                width: 10px;
                transform: rotate(45deg);
                animation: icon-line-tip-small 0.75s;
            }
            
            &.line-long {
                top: 15.2px;
                right: 3.2px;
                width: 18.8px;
                transform: rotate(-45deg);
                animation: icon-line-long-small 0.75s;
            }
        }
        
        .icon-circle {
            top: -1.6px;
            left: -1.6px;
            z-index: 10;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            position: absolute;
            box-sizing: content-box;
            border: 1.6px solid rgba($primary-color, 0.5);
        }
        
        .icon-fix {
            top: 3.2px;
            width: 2px;
            left: 10.4px;
            z-index: 1;
            height: 34px;
            position: absolute;
            transform: rotate(-45deg);
            background-color: transparent;
        }
    }
}

@keyframes rotate-circle-small {
    0% {
        transform: rotate(-45deg);
    }
    5% {
        transform: rotate(-45deg);
    }
    12% {
        transform: rotate(-405deg);
    }
    100% {
        transform: rotate(-405deg);
    }
}

@keyframes icon-line-tip-small {
    0% {
        width: 0;
        left: 0.4px;
        top: 7.6px;
    }
    54% {
        width: 0;
        left: 0.4px;
        top: 7.6px;
    }
    70% {
        width: 20px;
        left: -3.2px;
        top: 14.8px;
    }
    84% {
        width: 6.8px;
        left: 8.4px;
        top: 19.2px;
    }
    100% {
        width: 10px;
        left: 5.6px;
        top: 18px;
    }
}

@keyframes icon-line-long-small {
    0% {
        width: 0;
        right: 18.4px;
        top: 21.6px;
    }
    65% {
        width: 0;
        right: 18.4px;
        top: 21.6px;
    }
    84% {
        width: 22px;
        right: 0px;
        top: 14px;
    }
    100% {
        width: 18.8px;
        right: 3.2px;
        top: 15.2px;
    }
}