.ReactModalPortal .ReactModal__Overlay{
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2s ease-in-out;
    background: rgba(black, 0.5) !important;
}

.modal{
    position: relative;
    background: white;
    align-self: center;
    padding: 2rem;
    border-radius: $border-radius-small;
    width: 600px;
    outline: none !important;
    margin: 0px 1rem;

    @media screen and (max-width: $mobile-breakpoint) {
        padding: 1.5rem 1.2rem;
    }
}

.media_modal__close{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -0.6rem;
    right: -0.6rem;
    width: 1.5rem;
    height: 1.5rem;
    font-size: 1rem;
    text-align: center;
    cursor: pointer;
    color: white;
    z-index: 222;
    background-color: $warning-red;
    border-radius: 50%;
}

.action_modal{
    @extend .modal;
    width: 95vw;
    max-width: $contained-width;
    max-height: 80vh;
    overflow-y: auto;

    @media screen and (max-width: $mobile-breakpoint) {
        max-height: 70vh;
    }

    .container{
        margin-bottom: $margin-medium;
    }

    .chat_media_preview{
        padding: 0px;
        box-shadow: none;
        background-color: white;
        margin-top: $margin-medium;
        align-items: flex-start;
        height: unset;

        .chat_media_preview__container{
            max-width: unset !important;
            width: 100%;
            height: unset;
        }

        .chat_media_preview__container__default{
            padding: $padding-medium 0px;
        }

        .chat_media_preview__container img, .chat_media_preview__container video, .chat_media_preview__container .chat_media_preview__container__default{
            height: unset;
        }
    }
}

.modal__title{
    display: flex;
    font-weight: bold;
    font-size: 1.8rem;
    padding: 0.5rem 0px 1rem 0px;
    justify-content: space-between;
    align-items: center;

    .agent_profile_avatar{
        margin-bottom: 0px;
    }
}

.modal__subtitle{
    margin-bottom: $margin-medium;
    color: #777;
    font-weight: 500;
}

.modal__content{
    color: #888;
    margin-bottom: 1.5rem;
}

.modal__actions{
    display: flex;
    justify-content: flex-start;
    padding-top: 0.5rem;
    button{
        margin-right: 0.5rem;

        &:last-child{
            margin-right: 0px;
        }
    }
}

.modal_close{
    position: absolute;
    right: 0;
    top: 0;
    padding: 15px;
    font-size: 1.4rem;
    color: $light-grey;
    transition: all 0.1s ease;
    cursor: pointer;

    &:hover{
        color: $grey;
    }
}

.error_modal{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .error_modal_icon{
        font-size: 3rem;
        color: $warning-red;
    }
}