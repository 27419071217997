.menu_tabs{
    width: 100%;
    display: flex;
    flex-basis: 0 0;
    justify-content: space-between;
    padding: $padding-large;
}

.menu_tab{
    flex-grow: 1;
    padding: $padding-large $padding-medium;
    border-radius: $border-radius-medium;
    display: flex;
    align-items: center;
    box-shadow: 0px 0px 20px rgba(black, 0.1);
    margin-right: $margin-medium;
    width: 50%;
    text-decoration: none;
    transition: all 0.3s ease;

    &:hover{
        transform: translateY(-5px);
    }

    &:last-child{
        margin-right: 0px;

        .menu_tab__details__icon{
            background: #6e8bb1;
        }
    }
}

.menu_tab__details__icon{
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    color: white;
    margin-right: $margin-large;
    border-radius: 50%;
    font-size: 2rem;
    background: #39BBBA;
}

.menu_tab__details{
    flex-grow: 1;
}

.menu_tab__title{
    font-size: 1.2rem;
    font-weight: bold;
}

.menu_tab__description{
    font-weight: 300;
    color: $grey;
}

.menu_tab__details__arrow{
    
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    color: $primary-color;
    font-size: 2rem;
    flex-shrink: 0;
}