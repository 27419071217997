// Colours
$primary-color: rgb(35, 173, 165);
$offwhite: #f7f7f7;
$light-grey: #ced4da;
$grey: #5a5a5a;
$charcoal: #333;
$warning-red: #ff6868;
$border-color: rgba(0, 0, 0, 0.1);
$border-color-light: #f5f6f5;
$online-color: #60DA60;
//$background-color: linear-gradient(141deg,$primary-color 0%,$primary-color 30%,lighten($primary-color, 15%) 100%);
$background-color:$offwhite;

// Widths
$contained-width: 1140px;
$box-width: 800px;

// Paddings and Margin
$padding-small: 0.3rem;
$padding-medium: 1rem;
$padding-large: 1.5rem;
$margin-small: 0.5rem;
$margin-medium: 1rem;
$margin-large: 1.5rem;

// Curves
$border-radius-small: 0.3rem;
$border-radius-medium: 0.6rem;
$border-radius-large: 1rem;

// Breakpoints
$mobile-breakpoint: 896px;
$tablet-breakpoint: 990px;