.preloader{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    top: 0;
}

.preloader_text{
    margin-top: 0.5rem;
    display: block;
    color: $charcoal;
}

.line {
    height: .8rem;
    position: relative;
    margin-bottom: .3rem
}

.animated-background {
    animation-duration: 35s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-name: placeHolderShimmer;
    background: #f6f7f8;
    background: linear-gradient(to right,#eeeeee 8%,#f2f2f2 18%,#eeeeee 33%)
}

.text_preloader{
    @extend .animated-background;
    color: transparent;
    
    &.fit{
        width: fit-content;
    }
}