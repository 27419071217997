.student_degree_modal{
    display: flex;
    flex-direction: column;
    height: 90vh;
    max-height: 700px;
    padding: 35px !important;
    max-width: 520px;
    border-radius: $border-radius-medium !important;
}

.student_degree_modal__container{
    border: 1px solid rgba($primary-color, 0.3);
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: $padding-large;
    border-radius: $border-radius-medium;
}