.dashboard{
    position: fixed;
    display: flex;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    overflow: hidden;
}

.dashboard_container{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-grow: 1;
    overflow: hidden;
}

.dashboard_content{
    position: relative;
    display: flex;
    flex-grow: 1;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.dashboard_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $margin-large;
}

.dashboard_header__logo{
    flex-shrink: 0;

    img{
        max-width: 200px;
        max-height: 80px;
    }
}

.dashboard_header__details{
    flex-grow: 1;
}

.dashboard_header__details__title{
    font-size: 2rem;
    font-weight: bold;
}

.dashboard_header__details__subtitle{
    font-size: 1.5rem;
    font-weight: 300;
}

.dashboard_component{
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    overflow: hidden;
}