form{
    display: block;
}

.form-group__split{
    display: flex;
    justify-content: space-between;
    .form-group{
        width: calc(50% - 0.5rem);
    }

    @media (max-width: $mobile-breakpoint){
        display: block;
        .form-group{
            width: 100%;
        }
    }
}

.select-label{
    font-weight: 500;
    margin: 0px 0.2rem;
}

.form_select{
    input{
        margin-bottom: 0px;
    }

    margin-bottom: $margin-medium;
}

.form-group{
    position: relative;
    width: 100%;
    margin-bottom: $margin-medium;

    .input-field{
        display: block;
    }
}

label{
    font-size: 0.8rem;
    color: $grey;    
    margin-bottom: 0.3rem;
    display: block;
    color: #888;
}

.input-field{
    outline: none;
    width: 100%;
    line-height: 1.5rem;
    padding: .4rem .75rem;
    border: 1px solid $light-grey;
    border-radius: .25rem;
}

input[type="email"], input[type="password"], input[type="text"], input[type="number"]{
    @extend .input-field;
    font-family: inherit;
    -webkit-appearance: none;
}

textarea{
    @extend .input-field;
    font-family: inherit;
    resize: none;
    -webkit-appearance: none;
}

select{
    @extend .input-field;
    font-family: inherit;
    -webkit-appearance: none;
}

.help-block{
    font-size: 0.8rem;
    color: $warning-red;
}

.toggle_box{
    display: flex;
    align-items: center;

    svg{
        font-size: 1.2rem;
        margin-right: 0.3rem;
    }
}

.react-tel-input .form-control{
    width: 100% !important;
    height: 38.7px !important;
}

.form-group.checkbox{
    display: flex;

    input{
        margin-right: 0.3rem;
    }
}

.SingleDatePickerInput{
    border: none !important;

    .CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
        background-color: $primary-color;
        border-color: $primary-color;
    }

    .DayPickerKeyboardShortcuts_show__bottomRight::before{
        border-right-color: $primary-color !important;
    }
}