.student_menu{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: $padding-large;
}

.student_menu__item{
    margin-right: $margin-medium;
    text-decoration: none;
    padding: 0.5rem $padding-medium;
    border-bottom: 2px solid transparent;


    &:hover{
        border-bottom: 2px solid rgba($primary-color, 0.4);
    }

    &.active{
        border-bottom: 2px solid $primary-color !important;
    }

    &:last-child{
        margin-right: 0px;
    }
}