.degrees_page{
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    align-items: center;
}

.degrees{
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
    align-items: center;
    width: 100%;
}

.degrees_page_container{
    max-width: $box-width;
    padding: $padding-large;
    width: 100%;
    h1{
        font-weight: 600;
        color: $primary-color;
        text-align: center;
    }
}

.degrees_page_container__writeup{
    text-align: center;
}

.degree_list__item__details{
    flex-grow: 1;
}

.degree_list__item__details__prgoress_icon{
    flex-shrink: 0;
    margin-right: $margin-medium;
}

.degree_list_item__icon{
    display: flex;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 30px;
    color: white;
    box-shadow: -5px 5px 15px rgba(black, 0.1);
    
    &.in_progres{
        background-color: orange;
    }
    
    &.completed{
        background-color: $online-color;
    }
}

.degree_list{
    display: block;
    margin-top: 2rem;
    background-color: white;
    width: 100%;
    border-radius: $border-radius-large;
    box-shadow: 10px 25px 30px rgba(black, 0.1);
    .preloader{
        background-color: $offwhite;
    }
}

.degree_list_item__badge{
    font-size: 0.8rem;
    padding: 0.3rem 0.8rem;
    font-weight: 600;
    border-radius: $border-radius-large;
    color: white;

    &.in_progress{
        background-color: rgba($charcoal, 0.5);
    }

    &.completed{
        background-color: rgba($online-color, 0.8);
    }
}

.degree_content_main{
    position: relative;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    background-color: rgba($primary-color, 0.1);
    box-shadow: inset 0px 0px 25px rgba(black, 0.2);
    padding: $padding-large;
}

.degree_preview{
    display: flex;
    flex-grow: 1;
    justify-content: center;
    .react-pdf__Page{
        height: 100%;
        width: 100%;
        border-radius: $border-radius-large;
        overflow: hidden;
        box-shadow: 2.5px 2.5px 25px rgba(black, 0.1);
        animation: fadeIn 1s ease;
    }

    .react-pdf__Page__canvas {
        margin: 0 auto;
        height: 100% !important;
        width: 100% !important;
    }
}

.degree_preview_loader{
    position: absolute;
    width: 100%;
    height: 100%;
    box-shadow: inset 0px 0px 25px rgba(black, 0.2);
    top: 0%;
    left: 0%;
    background-color: lighten($primary-color, 50%);
}

.degree_list__item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.8rem 2rem;
    border-radius: $border-radius-large;
    transition: all 0.1s ease;
    text-decoration: none;
    cursor: pointer;

    &:hover{
        background-color: white;
    }
}

.degree_list__item__title{
    font-weight: 600;
    color: $primary-color;
    margin-bottom: $margin-small;
}

.degree_page{
    display: flex;
    flex-grow: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.degree_preview_loader{
    position: absolute;
    width: 100%;
    height: 100%;
    box-shadow: inset 0px 0px 25px rgba(black, 0.2);
    top: 0%;
    left: 0%;
    background-color: lighten($primary-color, 50%);
}

.degree_student_info{
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 450px;
    max-width: 40%;
    padding: $padding-large;
    flex-shrink: 0;
}


.degree_student_info__logo{
    display: flex;
    justify-content: flex-start;
    margin: $margin-large 0px;
    img{
        width: 100%;
        max-width: 200px;
    }
}

.degree_student_info__title{
    font-size: 1.85rem;
    font-weight: 300;
    color: $primary-color;
}

.degree_student_info__subtitle{
    font-size: 1.4rem;
    color: $grey;
    font-weight: 300;
    margin-bottom: $margin-small;
}

.degree_student_info__date_obtained{
    font-size: 0.8rem;
    margin-bottom: $margin-medium;
    font-weight: 600;
    font-style: italic;

    .completed{
        display: flex;
        align-items: center;
        svg{
            display: flex;
            align-items: center;
            margin-left: 0.5rem;
            font-size: 1.2rem;
            color: $primary-color;
        }
    }
}

.degree_student_info__subtitle_small{
    font-size: 0.8rem;
    color: $grey;
    margin-bottom: $margin-large;
    font-weight: 600;
}

.degree_student_info_heading{
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid $border-color;
    padding: 0.5rem 0px;
    font-weight: 600;
    color: $grey;
    align-items: flex-end;
}

.edit_contact_info_trigger{
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    color: $primary-color;
    cursor: pointer;

    svg{
        display: flex;
        align-items: center;
        margin-left: 0.2rem;
        line-height: 0.8rem;
    }

    &:hover{
        color: darken($primary-color, 10%);
    }
}

.degree_actions{
    display: block;
    width: 100%;

    button{
        display: block;
        width: 100%;
        border: 1px solid $primary-color;
        background-color: white;
        height: 50px;
        border-radius: 2rem;
        font-size: 15px;
        margin-bottom: $margin-small;
        font-weight: 600;
        color: $primary-color;
        cursor: pointer;
        transition: all 0.1s ease;

        &:hover{
            background-color: darken($primary-color, 20%);
            border-color: darken($primary-color, 20%);
            color: white;
        }

        &:last-child{
            margin-bottom: 0px;
            background-color: $primary-color;
            color: white;

            &:hover{
                background-color: darken($primary-color, 20%);
                border-color: darken($primary-color, 20%);
            }
        }
    }
}

.degree_student_info_item{
    margin-top: $margin-medium;
}

.degree_student_info_item__label{
    font-size: 0.8rem;
    font-weight: 600;
    color: $primary-color;
}

.degree_student_info_item__value{
    font-weight: 300;
    font-size: 1.2rem;
}