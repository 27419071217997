.user_info_sidebar{
    width: 300px;
    background-color: white;
}

.user_info{
    padding: 0px $padding-large;
    
    .table{
        width: 100%;
    }
}