.page_container{
    margin: $margin-medium auto;
    padding: $padding-large;
    height: fit-content;
    background-color: white;
    width: 95%;
    max-width: $contained-width;
    border-radius: $border-radius-medium;
    margin-bottom: 0px;

    &:last-child{
        margin-bottom: 2rem;
    }
}

.popup_box{
    position: absolute;
    top: 100%;
    margin-top: 10px;
    z-index: 22;
    background: white;
    padding: $padding-medium;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: $border-radius-medium;
    animation: fadeIn 0.3s;
}