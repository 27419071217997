.share_manager{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 90% !important;
    max-width: 1200px !important;
    max-height: 90%;
    overflow: hidden;

    .modal_actions{
        flex-shrink: 0;
    }
}

.share_table{
    position: relative;
    flex-grow: 1;
    overflow-y: auto;
    border-bottom: 1px solid $border-color;
    margin-bottom: $margin-medium;
    font-size: 0.8rem;

    .shared_name{
        font-weight: 600;
    }

    .access_revoked_message{
        color: $warning-red;
        font-weight: 600;
    }

    tbody{
        display: block;
        height: 100%;
        overflow-y: auto;
        width: 100%;
        max-height: 500px;
    }

    thead, tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;/* even columns width , fix width of table too*/
    }

    tr.expired{
        opacity: 0.5;
        background-color: $offwhite;
    }

    .expired-badge{
        background-color: $warning-red;
        color: white;
        font-weight: 600;
        padding: 0.2rem 0.4rem;
        border-radius: $border-radius-medium;
        width: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.7rem;
        margin-top: 0.2rem;
    }

    tr:last-child td{
        border-bottom: none !important;
    }
}

.info_included{
    display: flex;
    align-items: center;
    line-height: 0.8rem;
    margin-bottom: 0.2rem;
    font-style: italic;

    &:last-child{
        margin-bottom: 0px;
    }
    
    svg{
        margin-right: 0.2rem;
        display: flex;
        align-items: center;
        font-size: 1rem;

        &.check{
            color: $online-color;
        }

        &.cross{
            color: $warning-red;
        }
    }
}